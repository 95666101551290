import { memo } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { MainDateAndAddress } from '@/features/MainDateAndAddress';

const mainAuto = require('assets/images/landing/mainAuto.png');

import { LocaleType } from '@/types';
import { useErrors } from '@/utils/useErrors';

type Props = {
  locale: LocaleType;
};

export const MainBlock = memo(({ locale }: Props) => {
  const { t } = useTranslation();
  const { errors, setErrors } = useErrors();

  return (
    <div className="relative">
      <div
        className="absolute top-0 bottom-0 hidden w-1/2 md:block left-1/2"
        style={{
          backgroundImage: `url(${mainAuto})`,
        }}>
        <Image
          alt={`$t('mainPage.title1')} ${t('mainPage.title2')}`}
          className="object-cover object-center pointer-events-none"
          fill
          placeholder="blur"
          sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
          src={mainAuto}
        />
      </div>

      <div
        className="container relative z-10 px-6 pt-24 pb-6 mx-auto sm:px-0 md:pb-20 md:pt-32 lg:pb-24 lg:pt-56 xl:pb-28 xl:pt-72">
        <h1 className="landing-header">
          {t('mainPage.title1')}
          <br />
          {t('mainPage.title2')}
        </h1>
        <MainDateAndAddress errors={errors} locale={locale} setErrors={setErrors} type="main" />
      </div>
    </div>
  );
});
